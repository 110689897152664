import React, { useState, useEffect } from 'react';

function RewardsList() {
  const [loading, setLoading] = useState(true);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const [businessNames, setBusinessNames] = useState([]);

  const loadingMessages = ["Searching through transactions"];

  useEffect(() => {
    const cdrid = localStorage.getItem('cdrid');
    const headers = {
      'Content-Type': 'application/json',
      'tenantId': 'POKITPAL-DEV',
      'apiKey': 'SBtqonxHdQtXyx4cTnOZg2TOGcNsL4vMMBPeRvcCfH91ZuvYmMmTzGewI5cBHvOE'
    };

    const requestBody = { cdrid };

    fetch('https://api.dev.cdr.sipora.io/ro/rewards', {
      method: 'POST',
      headers,
      body: JSON.stringify(requestBody),
    })
    .then(response => response.json())
    .then(data => {
      // Extract unique business names
      const uniqueBusinessNames = [...new Set(data.map(item => item.enrich.merchant.businessName))];
      
      setBusinessNames(uniqueBusinessNames);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });

    const intervalId = setInterval(() => {
      setLoadingMessageIndex(prevIndex => (prevIndex + 1) % loadingMessages.length);
    }, 5000); // Change text every 5 seconds

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      {loading ? (
        <div>
          <p>This might take a few moments...</p>
          <p>{loadingMessages[loadingMessageIndex]}</p>
        </div>
      ) : (
        <div>
          <ul>
            {businessNames.map((businessName, index) => (
              <li key={index}>{businessName}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default RewardsList;
