import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import '../shared/styles.css';



function RegisterEmail() {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);


  const submitEmail = async (email) => {
    ReactGA.event({
      category: 'User',
      action: 'Submitted Email - Start',
      label: 'Register'
    });


    try {
      const response = await fetch('https://api.dev.cdr.sipora.io/launchro', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'tenantId': 'POKITPAL-DEV',
          'apiKey': 'SBtqonxHdQtXyx4cTnOZg2TOGcNsL4vMMBPeRvcCfH91ZuvYmMmTzGewI5cBHvOE' //PASS FROM APP
        },
        body: JSON.stringify({ email }),
      });

      const responseData = await response.json();

      if (response.ok) {
        console.log("API call successful:", responseData);
        console.log("Redirecting to:", responseData.roLink);

        // Use navigate from react-router-dom for navigation
        window.location.href = responseData.roLink;
      } else {
        // Handle non-successful responses
        console.error('Error submitting email:', responseData.message);
        alert(responseData.message || 'Error submitting email');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the email.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await submitEmail(email);
    
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="email">Email Address:</label>
      <input
        type="email"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <button className="goButton" type="submit" disabled={isSubmitting} >
      {isSubmitting ? 'Registering...' : 'Register'}</button>
    </form>
  );
}

export default RegisterEmail;
