import React, { useEffect } from 'react';
import ReactGA from 'react-ga4'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage.js';
import './App.css'
import LaunchConsent from './components/LaunchConsent.js';
import ConsentedSuccess from './components/ConsentedSuccess.js';
import ShowMissed from './components/ShowMissed.js';
import Consented from './components/ConsentedWithJobId.js';
import Notify from './components/Notify.js';
import Resume from './components/Resume.js';




function App() {
  useEffect(() => {
    ReactGA.initialize('G-VDPMGSP6MV'); 
  }, []);

  return (
    <Router>
          <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/Resume" element={<Resume />} />
        <Route path="/LaunchConsent" element={<LaunchConsent />} />
        <Route path="/ConsentedSuccess" element={<ConsentedSuccess />} />
        <Route path="/ShowMissed" element={<ShowMissed />} />
        <Route path="/Consented" element={<Consented />} />
        <Route path="/Notify" element={<Notify />} />


  
        {/* <Route path="/" element={<LandingPage />} /> */}
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
