import ReactGA from 'react-ga4';
import React, { useEffect, useState } from 'react';
import './shared/styles.css';
import Logo from './images/logo.jpg';

function LaunchConsent() {
  useEffect(() => {
    // Send a page view event to Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  
  const [redirectUrl, setRedirectUrl] = useState('');

  useEffect(() => {
    // Retrieve the consentLink from localStorage
    const storedConsentLink = localStorage.getItem('consentLink');

    // Set the redirectUrl to the stored consentLink (or your default URL)
    setRedirectUrl(storedConsentLink || 'https://example.com');
  }, []);

  // Define the function to be triggered when the button is clicked
  const handleButtonClick = () => {
    // You can use the retrieved redirectUrl here
    console.log('Button clicked! Redirecting to:', redirectUrl);

    // Add your logic here for what should happen when the button is clicked
    // For example, you can redirect the user to the stored URL
    window.location.href = redirectUrl;
  };

  return (
    <div className="container">
      
      <img src={Logo} alt="Logo" className="hero-image"/>
        <div className="header">

           
          <h1 className="title">Connect My Accounts</h1>

          <p className="subtext">
            The Rewards Optimiser Connects Securely To Your Bank, Analyses Your
            Transactions, And Show You Exactly What You've Been Missing.
          </p>

          <p>By clicking connect we will ask you to connect using OpenBanking</p>
          <button className="goButton" onClick={handleButtonClick}>Connect</button>
          </div>
          <div className="footer">

        </div></div>
          
        
  );
}

export default LaunchConsent;
