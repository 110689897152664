import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import './shared/styles.css';
import StartButton from './buttons/start';
import ShowMissedButton from './buttons/ShowMissedButton'; // Assuming this component exists
import Logo from './images/logo.jpg'

function Resume() {
  useEffect(() => {
    // Send a page view event to Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  const queryParams = new URLSearchParams(window.location.search);
  const cdrid = queryParams.get('cdrid');
  const existingValue = queryParams.get('existing') || 'false'; 

  // Optionally set in localStorage if needed elsewhere
  localStorage.setItem('cdrid', cdrid);
  localStorage.setItem('existing', existingValue);

  // Determine which button to display based on the existingValue
  const isExistingSuccessful = existingValue === 'successful';
  
  return (
    <div className="container">
   <img src={Logo} alt="logo" className="hero-image" />

      <div className="header">
        <h1 className="title">Unlock Missed Rewards</h1>
        <p className="subtext">
          Did you know that most people are missing out on $3,769 in cashback over a 12 month period?</p> 
          <p className="subtext">
          Why not let the Rewards Optimiser Show You Exactly What You've Been Missing.
        </p>
        <p>Ready To See What You're Leaving On The Table?</p>
      
      {isExistingSuccessful ? (
          <ShowMissedButton cdrid={cdrid} />
        ) : (
          <StartButton cdrid={cdrid} />
        )}
      </div>      </div>
  
  );
}

export default Resume;