import React from 'react';
import '../shared/styles.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import ReactGA from 'react-ga4';

function NotifyButton() {
  const navigate = useNavigate(); // Hook for navigation
  const cdrid = localStorage.getItem('cdrid');
  const tenant = 'POKITPAL-DEV'

  const submitCdrid = async () => {
    ReactGA.event({
      category: 'User',
      action: 'Notify Me Button',
      label: 'Notify Me'
    });

    navigate('/Notify');

    try {
      await fetch('https://ro_accept_email.cdrproducts.workers.dev', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cdrid, tenant }), // Use the cdrid from localStorage
      });

    } catch (error) {
      console.error('Error:', error);
      // Even though there's an error, the user has already been navigated to the new page.
    }
  };

  return (
    <button className="goButton" onClick={submitCdrid} type="button">Notify Me</button>
  );
}

export default NotifyButton;
