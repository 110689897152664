import React, { useState, useEffect } from 'react';
import ShowMissedButton from './buttons/ShowMissedButton';

function RetrieveTransactions() {
  const [loading, setLoading] = useState(true);

  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);

  const loadingMessages = [
    "Retrieving Data...",
    "Retrieving Transactions...",
    "Searching for Cashback..."
  ];

  useEffect(() => {
    // Retrieve the cdrid from localStorage
    const cdrid = localStorage.getItem('cdrid');
    // Define your custom headers here
    const headers = {
      'Content-Type': 'application/json',
      'tenantId': 'POKITPAL-DEV',
      'apiKey': 'SBtqonxHdQtXyx4cTnOZg2TOGcNsL4vMMBPeRvcCfH91ZuvYmMmTzGewI5cBHvOE' //PASS FROM APP
    };

    const requestBody = {
      cdrid: cdrid,
    };

    // Fetch data from your API with custom headers
    fetch('https://api.dev.cdr.sipora.io/ro/checkconnection', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestBody),
    })
    .then((response) => {
      if (response.status === 201) {
        // If the response status code is 201, set loading to false immediately
        setLoading(false);
        return;
      } else if (response.status === 200) {
        // If the response status code is 200, proceed with the existing logic
        return response.json(); // Make sure to return the JSON parsing promise
      } else {
        // Handle other status codes or errors as necessary
        throw new Error('Unexpected response status:', response.status);
      }
    })
    .then(() => {
      if (loading) {
        // Only execute this if we're still loading (status was 200)
        setTimeout(() => {
          setLoading(false);
        }, 100000); 
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });

    const intervalId = setInterval(() => {
      setLoadingMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length);
    }, 5000); // Change text every 5 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);
 
   return (
    <div>
      {loading ? (
        // Display a loading indicator while data is being fetched
        <div><p>This might take a few moments...</p>
      <p>{loadingMessages[loadingMessageIndex]}</p>
      </div>
      ) : (
        // Display the fetched data once loading is complete
        <div>
         <div className="footer">
        
            <ShowMissedButton />
          </div>
        </div>

      )}
    </div>
  );
}


export default RetrieveTransactions;
