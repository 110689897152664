import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from './images/logo.jpg';
import ReactGA from 'react-ga4';

function Consented() {
  const [statusMessage, setStatusMessage] = useState('Confirming Connection...'); // Initial message
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Send a page view event to Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const jobId = searchParams.get('jobId');
    localStorage.setItem('jobId', jobId);


    if (!jobId) {
      // If jobId is not present in the URL, update the status message and don't navigate
      setStatusMessage('We have encountered an error in confirming your connection, please try again later.');
      return; // Stop further execution
    }

    function checkJobStatus(jobId) {
      fetch('https://dev_basiq_check_job.cdrproducts.workers.dev', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'tenantId': 'POKITPAL-DEV',
          'apiKey': 'SBtqonxHdQtXyx4cTnOZg2TOGcNsL4vMMBPeRvcCfH91ZuvYmMmTzGewI5cBHvOE' //PASS FROM APP
        },
        body: JSON.stringify({ jobId: jobId }),
      })
      .then(response => {
        const status = response.status;
        return response.json().then(data => ({ status, data }));
      })
      .then(({ status, data }) => {
        // Check the API response for a successful status and a cdrid
        if (status === 200 && data.cdrid) {
          // Check if cdrid is not already set in localStorage
          if (!localStorage.getItem('cdrid')) {
            // Set cdrid in localStorage
            localStorage.setItem('cdrid', data.cdrid);
          }
          const cdrid = data.cdrid
          // Navigate to the success page
          navigate(`/ConsentedSuccess?cdrid=${cdrid}`);
        } else if (status === 202) {
          // If the job is still in progress, wait 2 seconds and check again
          setTimeout(() => checkJobStatus(jobId), 2000);
        } else {
          // If the request was not successful, set an error message
          setStatusMessage('We have encountered an error in confirming your connection, please try again later or contact info@pokitpal.com for assistance.');
        }
      })
      .catch(error => {
        console.error('Error fetching job status:', error);
        setStatusMessage('An error occurred while confirming the connection. Please contact info@pokitpal.com for assistance');
      });
    }
    
    
    // Call the function initially with the jobId
    checkJobStatus(jobId);
  }, [location.search, navigate]); // Updated dependencies
    

  return (
    <div className="container">
      <img src={Logo} alt="logo" className="hero-image" />
      <div className="header">
        <p className="subtext">
          {statusMessage}
        </p>
      </div> 
    </div>
  );
}

export default Consented;