import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import './shared/shared.css';
import Logo from './images/logo.jpg'




function Notify() {
  useEffect(() => {
    // Send a page view event to Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="container">
   <img src={Logo} alt="logo" className="hero-image" />

      <div className="header">
     
     
      {/* <h1 className="title">NOTIFY ME</h1> */}

      <p className="subtext">
        You will receive an email in 5-7 minutes.
        </p>
        
        <button
  className="goButton"
  onClick={() => {
    // Track the event with Google Analytics
    window.gtag('event', 'join_rewards', {
      event_category: 'Rewards',
      event_label: 'Join Rewards Button Click',
      value: 'click'
    });

    // Redirect the user after tracking the event
    window.location.href = 'https://pokitpal.com/rewards';
  }}
>
  Join Rewards
</button>
        </div>
        <div className="footer">
    
            </div></div>

         

      
     
  );
}

export default Notify;
