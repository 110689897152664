import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import './shared/shared.css';
import RetrieveTransactions from './RetrieveTransactions';
import Logo from './images/logo.jpg';
import NotifyButton from './buttons/notifyButton';

function ConsentedSuccess() {
  useEffect(() => {
    // Send a page view event to Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  const queryParams = new URLSearchParams(window.location.search);
  const cdrid = queryParams.get('cdrid');

  // Optionally set in localStorage if needed elsewhere
  localStorage.setItem('cdrid', cdrid);

  const [showNotifyButton, setShowNotifyButton] = useState(false); // State to control the display of the NotifyButton

  useEffect(() => {
    // Set a timer to change showNotifyButton to true after 10 seconds
    const timer = setTimeout(() => {
      setShowNotifyButton(true);
    }, 3000); 

    // Clean up the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, []); // Empty dependency array means this effect runs only once after the initial render

  return (
    <div className="container">
      <img src={Logo} alt="logo" className="hero-image" />
      <div className="header">
     
        <h1 className="title">Searching...</h1>
        
        <RetrieveTransactions cdrid={cdrid}/> 
        {/* Conditionally render the NotifyButton based on showNotifyButton state */}
       
        <p className="subtext">
          Why wait around? Opt in to get notified instantly once we've unlocked your potential rewards.
        </p>
       
      
      {showNotifyButton && <NotifyButton />}
    </div> </div>
  );
}

export default ConsentedSuccess;
