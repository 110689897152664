import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ShowMissedButton() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const cdrid = localStorage.getItem('cdrid');

  const handleButtonClick = async () => {
    setIsLoading(true);

    try {
      // Make both API calls simultaneously using Promise.all
      const [response, altResponse] = await Promise.all([
        fetch('https://api.dev.cdr.sipora.io/ro/rewards/totals', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'tenantId': 'POKITPAL-DEV',
            'apiKey': 'SBtqonxHdQtXyx4cTnOZg2TOGcNsL4vMMBPeRvcCfH91ZuvYmMmTzGewI5cBHvOE' //PASS FROM APP
          },
          body: JSON.stringify({
            cdrid: cdrid,
          }),
        }),
        fetch('https://api.dev.cdr.sipora.io/ro/alternates/totals', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'tenantId': 'POKITPAL-DEV',
            'apiKey': 'SBtqonxHdQtXyx4cTnOZg2TOGcNsL4vMMBPeRvcCfH91ZuvYmMmTzGewI5cBHvOE' //PASS FROM APP
          },
          body: JSON.stringify({
            cdrid: cdrid,
          }),
        }),
      ]);

      if (!response.ok) {
        throw new Error(`API call failed: ${response.status}`);
      }
      if (!altResponse.ok) {
        throw new Error(`Alternate API call failed: ${altResponse.status}`);
      }

      const [responseData, altResponseData] = await Promise.all([response.json(), altResponse.json()]);
      const rewardsCount = parseFloat(responseData.count);
      const totalRewardsValue = parseFloat(responseData.totalRewardsValue);
      const uniqueMerchants = parseFloat(responseData.uniqueMerchants);
      const averageRewardValue = parseFloat(responseData.averageRewardValue);
      
      const altRewardsCount = parseFloat(altResponseData.count);
      const altTotalRewardsValue = parseFloat(altResponseData.totalRewardsValue);
      const altUniqueMerchants = parseFloat(altResponseData.uniqueMerchants);
      const altAverageRewardValue = parseFloat(altResponseData.averageRewardValue);
      
      localStorage.setItem('rewardsCount', rewardsCount);
      localStorage.setItem('totalRewardsValue', totalRewardsValue);
      localStorage.setItem('uniqueMerchants', uniqueMerchants);
      localStorage.setItem('averageRewardValue', averageRewardValue);
      
      localStorage.setItem('altRewardsCount', altRewardsCount);
      localStorage.setItem('altTotalRewardsValue', altTotalRewardsValue); // Corrected typo here
      localStorage.setItem('altUniqueMerchants', altUniqueMerchants);
      localStorage.setItem('altAverageRewardValue', altAverageRewardValue);
      

      navigate('/ShowMissed');

      setIsLoading(false);
    } catch (error) {
      console.error('API call failed:', error);
      alert(error.message);
      setIsLoading(false);
    }
  };

  return (

      <button className="goButton" onClick={handleButtonClick} disabled={isLoading}>
        {isLoading ? 'Loading' : 'Show Missed'}
      </button>

  );
}

export default ShowMissedButton;
